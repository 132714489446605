@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600&display=swap);
body,
html,
#root,
.auth-wrapper {
  font-family: "Raleway", sans-serif !important;
  width: 100%;
  height: 100%;
  font-size: 0.85rem;
}

.index-bg {
  background: url(/static/media/back-general.cbcd9fc9.jpg) no-repeat 50% fixed;
  min-height: 100%;
  background-size: cover;
}

.pays-bg {
  background: url(/static/media/pays.de96727f.jpg) no-repeat 50% fixed;
  min-height: 100%;
  background-size: cover;
}

.coupons-bg {
  background: url(/static/media/back-coupon.0e193dfa.png) no-repeat 50% fixed;
  min-height: 100%;
  background-size: cover;
}

.login-bg {
  background: url(/static/media/back-login.0a7d22ba.jpg) no-repeat 50% fixed;
  min-height: 100%;
  background-size: cover;
}

.signup-bg {
  background: url(/static/media/back-signup.1416051c.jpg) no-repeat 50% fixed;
  min-height: 100%;
  background-size: cover;
}

.dashboard-bg {
  background: url(/static/media/explore.f6e47477.gif) no-repeat 50% fixed;
  min-height: 100%;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.dashboard-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgb(27, 28, 29);
  background: linear-gradient(
    180deg,
    rgba(27, 28, 29, 1) 50%,
    rgba(121, 0, 255, 1) 75%,
    rgba(121, 0, 255, 0) 100%
  );
  opacity: 0.8;
  z-index: -1;
}

.reset-bg {
  background: url(/static/media/reset-password.1f9e5b1c.jpg) no-repeat 50% fixed;
  min-height: 100%;
  background-size: cover;
}

.center {
  display: flex;
  justify-content: center;
}

.padd-top-0 {
  padding-top: 0% !important;
}

.padd-top-1 {
  padding-top: 1%;
}

.padd-top-2 {
  padding-top: 2%;
}

.padd-top-3 {
  padding-top: 3.5%;
}

.padd-top-4 {
  padding-top: 4%;
}

.padd-top-4-5 {
  padding-top: 4.5em;
}

.padd-top-5 {
  padding-top: 5%;
}

.padd-top-6 {
  /*padding-top: 6%;*/
  padding-top: 6.5em;
}

.padd-top-8 {
  padding-top: 8%;
}

.padd-top-10 {
  padding-top: 10%;
}

.padd-bot-0 {
  padding-bottom: 0%;
}

.padd-bot-1 {
  padding-bottom: 1%;
}

.padd-bot-2 {
  padding-bottom: 2%;
}

.padd-bot-3 {
  padding-bottom: 3%;
}

.padd-bot-5 {
  padding-bottom: 5%;
}

.padd-bot-10 {
  padding-bottom: 10%;
}

.div-50 {
  text-align: center;
}

.padd-all-3 {
  padding: 3%;
}

.card {
  border-radius: 1rem !important;
}

.badge-color {
  background-color: rgba(255, 0, 0, 0.2) !important;
  color: rgb(255, 255, 255) !important;
}

.btn-primary:hover {
  color: #fff;
  background-color: #4e088b !important;
  border-color: #4e088b !important;
}

.btn-primary {
  color: #fff;
  background-color: #ab00ff !important;
  border-color: #ab00ff !important;
}

.trans {
  background-color: #ffffffc7 !important;
}

.trans2 {
  background-color: #ffffff29 !important;
}

.title-practices {
  font-size: 1.1rem;
  color: #652c8f;
  font-weight: 600;
}

.contrast {
  filter: grayscale(100%);
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.absolute {
  position: absolute;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.z-10 {
  z-index: 10;
}

.p-10 {
  padding: 2.5rem;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.max-w-\[38rem\] {
  max-width: 38rem;
}

.backdrop-blur-\[40px\] {
  /*filter: blur(4px);
  -webkit-filter: blur(4px);*/
  background-color: rgb(30 26 29 / 50%);
}

.p-4 {
  padding: 1rem;
}

.rounded-3xl {
  border-radius: 0.5rem;
}

.text-8xl {
  font-size: 3.8rem;
  line-height: 0.75;
}

.text-5xl {
  font-size: 1.8rem;
  line-height: 0.75;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mr-auto {
  margin-right: auto;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.rounded-lg {
  border-radius: 0.5rem;
}

.text-center-about {
  margin: auto;
  width: 70%;
  padding: 70px 0;
}

.ui.card > .image,
.ui.cards > .card > .image {
  background: rgb(255 255 255 / 0%) !important;
}

.ui.cards > .green.card,
.ui.green.card,
.ui.green.cards > .card {
  box-shadow: 0 0 0 1px #d4d4d5, 0 5px 0 0 #21ba45, 0 1px 3px 0 #d4d4d5 !important;
}

.ui.cards > .grey.card,
.ui.grey.card,
.ui.grey.cards > .card {
  box-shadow: 0 0 0 1px #d4d4d5, 0 5px 0 0 #767676, 0 1px 3px 0 #d4d4d5 !important;
}

a {
  text-decoration: none !important;
}

.no-decoration {
  color: #0f0f0f !important;
}

/********************************************************************/
.bg-color-segment {
  background-color: #ecf0f3 !important;
}

.input-form,
.ui.fluid.dropdown {
  width: 100% !important;
  height: 30px !important;
  margin: 4px 0 !important;
  padding-left: 10px;
  font-size: 13px !important;
  letter-spacing: 0.15px !important;
  border: none !important;
  outline: none !important;
  background-color: #ecf0f3f6 !important;
  transition: 0.25s ease !important;
  border-radius: 8px !important;
  box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9 !important;
}

.ui.purple.button,
.ui.purple.buttons .button {
  background-color: #652c8f !important;
}

img {
  border-radius: 1rem !important;
}

.ui.cards > .purple.card,
.ui.purple.card,
.ui.purple.cards > .card {
  box-shadow: 0 0 0 1px #d4d4d5, 0 5px 0 0 #a333c8, 0 1px 3px 0 #d4d4d5 !important;
}

.ui.blue.card,
.ui.blue.cards > .card,
.ui.cards > .blue.card {
  box-shadow: 0 0 0 1px #d4d4d5, 0 5px 0 0 #2185d0, 0 1px 3px 0 #d4d4d5 !important;
}

.ui.black.card,
.ui.black.cards > .card,
.ui.cards > .black.card {
  box-shadow: 0 0 0 1px #d4d4d5, 0 5px 0 0 #1b1c1d, 0 1px 3px 0 #d4d4d5 !important;
}

.ui.cards > .violet.card,
.ui.violet.card,
.ui.violet.cards > .card {
  box-shadow: 0 0 0 1px #d4d4d5, 0 5px 0 0 #6435c9, 0 1px 3px 0 #d4d4d5 !important;
}

.ui.modal {
  position: initial !important;
}

.modal {
  height: auto !important;
}

.ui.message {
  padding: 0.1em 0.5em !important;
}

.pricing-table-subtitle {
  margin-top: 68px;
  font-weight: normal;
}

.pricing-table-title {
  font-weight: bold;
  margin-bottom: 68px;
}

.pricing-card {
  border: none;
  border-radius: 10px;
  margin-bottom: 40px;
  text-align: center;
  transition: all 0.6s;
}

.pricing-card:hover {
  box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
}

.pricing-card.pricing-card-highlighted {
  box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
}

.pricing-card:hover {
  box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
  transform: translateY(-10px);
}

.pricing-card .card-body {
  padding-top: 10px; /*55*/
  padding-bottom: 10px; /*62*/
}

.pricing-plan-title {
  font-size: 20px;
  color: #000;
  margin-bottom: 11px;
  font-weight: normal;
}

.pricing-plan-cost {
  font-size: 25px;
  color: #000;
  font-weight: bold;
  margin-bottom: 29px;
}

.pricing-plan-icon {
  display: inline-block;
  /*width: 40px;
  height: 40px;*/
  font-size: 40px;
  line-height: 1;
  margin-bottom: 24px;
}

.pricing-plan-basic .pricing-plan-icon {
  color: #fe397a;
}

.pricing-plan-pro .pricing-plan-icon {
  color: #10bb87;
}

.pricing-plan-enterprise .pricing-plan-icon {
  color: #5d78ff;
}

.pricing-plan-features {
  list-style: none;
  padding-left: 0;
  font-size: 14px;
  line-height: 2.14;
  margin-bottom: 35px;
  color: #303132;
}

.pricing-plan-purchase-btn {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  width: 145px;
  height: 45px;
  border-radius: 22.5px;
  transition: all 0.4s;
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.pricing-plan-basic .pricing-plan-purchase-btn {
  background-color: #fe397a;
  color: #fff;
}

.pricing-plan-basic .pricing-plan-purchase-btn:hover {
  box-shadow: 0 3px 0 0 #b7013d;
}

.pricing-plan-basic .pricing-plan-purchase-btn:active {
  transform: translateY(3px);
  box-shadow: none;
}

.pricing-plan-pro .pricing-plan-purchase-btn {
  background-color: #10bb87;
  color: #fff;
}

.pricing-plan-pro .pricing-plan-purchase-btn:hover {
  box-shadow: 0 3px 0 0 #0a7554;
}

.pricing-plan-pro .pricing-plan-purchase-btn:active {
  transform: translateY(3px);
  box-shadow: none;
}

.pricing-plan-enterprise .pricing-plan-purchase-btn {
  background-color: #5d78ff;
  color: #fff;
}

.pricing-plan-enterprise .pricing-plan-purchase-btn:hover {
  box-shadow: 0 3px 0 0 #1138ff;
}

.pricing-plan-enterprise .pricing-plan-purchase-btn:active {
  transform: translateY(3px);
  box-shadow: none;
}

.dark-it {
  background-color: #000 !important;
  color: #fff !important;
}

.StripeElement {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
}

.StripeElement--invalid {
  border: 1px solid red;
}

.react-tel-input .form-control {
  width: inherit !important;
  font-family: "Raleway", sans-serif !important;
}

/*********/

.slider-wrapper {
  position: relative;
  height: 65vh;
  overflow: hidden;
  /*max-width: 800px;*/
  margin: 0 auto;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider-content * {
  pointer-events: none;
}

.slider-content .inner button {
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 48px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #fff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

.slide h1 {
  transition: all 0.3s ease;
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.3s ease;
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.3s ease;
  transform: translateY(20px);
  opacity: 0;
}

.slide section * {
  transition: all 0.3s ease;
}

.slide section img {
  transform: translateX(-10px);
  opacity: 0;
}

.slide section span {
  transform: translateY(-10px);
  opacity: 0;
}

.slide section span strong {
  transform: translateY(10px);
  opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  transform: translateX(0);
  transition-delay: 0.9s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  transition-delay: 1.5s;
}

.slide.animateOut h1 {
  transition-delay: 0.3s;
}

.slide.animateOut p {
  transition-delay: 0.2s;
}

.slide.animateOut section span {
  transition-delay: 0.1s;
}

.slide.animateOut section span strong {
  transition-delay: 0s;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  background: url("https://img.icons8.com/fluency/96/000000/circled-chevron-right.png")
    no-repeat center center / 50px;
  width: 50px;
  height: 50px;
  text-indent: -9999px;
  cursor: pointer;
}

.previousButton:hover,
.nextButton:hover {
  background: url("https://img.icons8.com/fluency/96/000000/circled-chevron-right.png")
    no-repeat center center / 50px;
}

.previousButton {
  left: 0;
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: -10px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -10px;
}

/*******************/

.ui.menu .item > img:not(.ui) {
  width: 8.5em !important;
  border-radius: 0rem !important;
  padding: 0.5px !important;
}

.wrapped {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.checkout-button {
  background: linear-gradient(180deg, #ffffff 0%, #d9dfff 100%);
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 20px 40px rgba(23, 0, 102, 0.2);
  border-radius: 20px;

  display: flex;

  padding: 4px;
  width: 169px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;

  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.checkout-button:focus {
  outline: none;
}

.checkout-button:hover {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1),
    0px 30px 60px rgba(23, 0, 102, 0.3);
  transform: translateY(-2px);
}

.checkout-button .grey-circle {
  background: rgba(68, 66, 178, 0.1);
  box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-button .purple-circle {
  background: linear-gradient(200.44deg, #4316db 13.57%, #9076e7 98.38%);
  box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.1);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-button .icon {
  width: 20px;
  height: 20px;
  margin: 0px;
}

.checkout-button .text-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 4px;
  margin: auto;
  text-align: left;
  margin-left: 16px;
}

.checkout-button .text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 130%;
  margin: 0;
  color: rgba(0, 0, 0, 1);
  margin: 0;
}

.red-secondary {
  background-color: #e25353 !important;
  box-shadow: none !important;
}

.orange-secondary {
  background-color: #f58d49 !important;
  box-shadow: none !important;
}

.yellow-secondary {
  background-color: #fcca39 !important;
  box-shadow: none !important;
}

.olive-secondary {
  background-color: #c4d646 !important;
  box-shadow: none !important;
}

.green-secondary {
  background-color: #4dc86a !important;
  box-shadow: none !important;
}

.teal-secondary {
  background-color: #33c4bd !important;
  box-shadow: none !important;
}

.blue-secondary {
  background-color: #4d9dd9 !important;
  box-shadow: none !important;
}

.violet-secondary {
  background-color: #7f61bf !important;
  box-shadow: none !important;
}

.text-whi {
  color: #fff !important;
}

.ui.message {
  padding: 1em !important;
}

.required > label:after {
  margin: -0.2em 0 0 0.2em;
  content: "*";
  color: #db2828;
}

.video-wrapper {
  position: relative !important;
  padding-bottom: 50% !important;
  /*padding-bottom: 56.25% !important;*/
  /*padding-bottom: 54% !important;*/
  overflow: hidden !important;
}

.iframe {
  overflow: hidden !important;
  border: 0px !important;
  align-self: center !important;
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  width: 100% !important;
  height: 100% !important;
}

.ui.menu .active.item {
  background: #fff !important;
  color: #000 !important;
}

.ui.menu {
  display: flex;
  margin: 1rem 0;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  /* background: #fff; */
  font-weight: 400;
  border: 1px solid rgba(34, 36, 38, .15);
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  border-radius: .28571429rem;
  min-height: 2.85714286em;
}
